// App.js - WEB
import React, { Component } from "react";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import WebRoutes from './WebRoutes'
import HomeScreen from "../../components/src/HomeScreen";
import './App.css';


const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});


class App extends Component {
  render() {
    return (
      <div>
        <WebRoutes />
      </div>
    );
  }
}

export default App;