import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';

const Splash = lazy(() =>
  import('../../blocks/email-account-login/src/Splash.web')
);
const EmailLogin = lazy(() =>
  import('../../blocks/email-account-login/src/EmailLogin.web')
);
const Resetpassword = lazy(() =>
  import('../../blocks/forgot-password/src/Resetpassword.web')
);
const Passwordchange = lazy(() =>
  import('../../blocks/forgot-password/src/Passwordchange.web')
);
const PasswordChangeSuccessful = lazy(() => 
  import('../../blocks/forgot-password/src/PasswordChangeSuccessful.web')
);
const LinkExpired = lazy(() => 
  import ('../../blocks/forgot-password/src/LinkExpired.web')
);
const Signupusers = lazy(() =>
  import('../../blocks/email-account-registration/src/GymManager/Signupusers.web')
);
const SignUp = lazy(() =>
  import('../../blocks/email-account-registration/src/SignUp.web')
);
const Confirmation = lazy(() =>
  import('../../blocks/email-account-registration/src/Confirmation.web')
);
const Deactivation = lazy(() =>
  import('../../blocks/email-account-registration/src/Deactivation.web')
);
const Success = lazy(() =>
  import('../../blocks/email-account-registration/src/Success.web')
);
const Signupcoach = lazy(() =>
  import('../../blocks/email-account-registration/src/Signupcoach.web')
);
const Checkmail = lazy(() =>
  import('../../blocks/email-account-registration/src/Checkmail.web')
);
const Singlegymsuccess = lazy(() =>
  import('../../blocks/email-account-registration/src/Singlegymsuccess.web')
);
const GymList = lazy(() =>
  import('../../blocks/email-account-registration/src/GymList.web')
);
const SignUpManager = lazy(() =>
  import('../../blocks/email-account-registration/src/GymManager/SignUpManager.web')
);
const CheckEmailManager = lazy(() =>
  import('../../blocks/email-account-registration/src/CheckEmailManager.web')
);
const HelpUS = lazy(() =>
  import('../../blocks/email-account-registration/src/GymManager/HelpUs.web')
);
const SubscriptionPlan = lazy(() =>
  import('../../blocks/email-account-registration/src/GymManager/SubscriptionPlan.web')
);
const TermAndConditionss = lazy(() =>
  import('../../blocks/termsconditions/src/TermAndConditionss.web')
);
const Privacy = lazy(() =>
  import('../../blocks/termsconditions/src/Privacy.web')
);
const Settings = lazy(() =>
  import('../../blocks/adminconsole2/src/GymSettings.web')
);
const Accountconfirm = lazy(() =>
  import('../../components/src/CommonContainer/CommonAcountConfirm')
);
const AdminDashboard = lazy(() =>
  import('../../blocks/adminconsole2/src/AdminDashboard.web')
);
const ManagerDashboard = lazy(() =>
  import('../../blocks/adminconsole2/src/ManagerDashboard.web')
);
const EquipmentAdd = lazy(() =>
  import('../../blocks/cfcoursecreation10/src/Equipment/EquipmentAdd.web')
);
const EquipmentEdit = lazy(() =>
  import('../../blocks/cfcoursecreation10/src/Equipment/EquipmentEdit.web')
);
const EquipmentList = lazy(() =>
  import('../../blocks/cfcoursecreation10/src/Equipment/EquipmentList.web')
);
const MediaEdit = lazy(() =>
  import('../../blocks/cfcoursecreation10/src/Media/MediaEdit.web')
);
const Medialist = lazy(() =>
  import('../../blocks/cfcoursecreation10/src/Media/Medialist.web')
);
const MediaAdd = lazy(() =>
  import('../../blocks/cfcoursecreation10/src/Media/MediaAdd.web')
);
const SkillandDrillManagement = lazy(() =>
  import('../../blocks/contentmanagement/src/skillanddrill/SkillandDrillManagement.web')
);
const AddSkillandDrill = lazy(() =>
  import('../../blocks/contentmanagement/src/AddSkillandDrill.web')
);
const DetailSkillandDrill = lazy(() =>
  import('../../blocks/contentmanagement/src/DetailsSkillandDrill.web')
);
const PlanList = lazy(() =>
  import('../../blocks/cfcoursecreation10/src/Plan/PlanList.web')
);
const PlanAdd = lazy(() =>
  import('../../blocks/cfcoursecreation10/src/Plan/PlanAdd.web')
);
const Users = lazy(() =>
  import('../../blocks/customisableuserprofiles/src/UsersList/UsersList.web')
);
const UsersCatalogue = lazy(() =>
  import('../../blocks/catalogue/src/UsersCatalogue.web')
);
import PrivateRoute from './PrivateRoutes.web';
import Loader from '../../components/src/Loader';

function WebRoutes(rootProps: any) {
  return (
    <Suspense fallback={<Loader loading />}>
      <Switch>
        <Route path="/" exact render={(props: any) => <Splash {...props} />} />
        <Route
          path="/emaillogin"
          exact
          render={(props: any) => <EmailLogin {...props} />}
        />
        <Route
          path="/signupmanager"
          exact
          render={(props: any) => <SignUpManager {...props} />}
        />
        <Route
          path="/resetpassword"
          exact
          render={(props: any) => <Resetpassword {...props} />}
        />
        <Route
          path="/passwordChangeSuccessful"
          exact
          render={(props: any) => <PasswordChangeSuccessful {...props} />}
        />
        <Route
          path="/passwordLinkExpired"
          exact
          render={(props: any) => <LinkExpired id="passwordLinkExpired" {...props} />}
        />
        <Route
          path="/verificationLinkExpired"
          exact
          render={(props: any) => <LinkExpired id="verificationLinkExpired" {...props} />}
        />
        <Route
          path="/passwordchange"
          exact
          render={(props: any) => <Passwordchange {...props} />}
        />
        <Route
          path="/signupusers"
          exact
          render={(props: any) => <Signupusers {...props} />}
        />
        <Route
          path="/signup"
          exact
          render={(props: any) => <SignUp {...props} />}
        />

        <Route
          path="/success"
          exact
          render={(props: any) => <Success {...props} />}
        />
        <Route
          path="/signupcoach"
          exact
          render={(props: any) => <Signupcoach {...props} />}
        />

        <Route
          path="/singlegymsuccess"
          exact
          render={(props: any) => <Singlegymsuccess {...props} />}
        />

        <Route
          path="/subscriptionplan"
          exact
          render={(props: any) => <SubscriptionPlan {...props} />}
        />
        <Route
          path="/termsandcondition"
          exact
          render={(props: any) => <TermAndConditionss {...props} />}
        />
        <Route
          path="/privacy"
          exact
          render={(props: any) => <Privacy {...props} />}
        />
        <Route
          path="/accountconfirm"
          exact
          render={(props: any) => <Accountconfirm {...props} />}
        />

        <Route
          path="/Medialist"
          exact
          render={(props: any) => <Medialist {...props} />}
        />
        <Route
          path="/manageskilldrill"
          exact
          render={(props: any) => <SkillandDrillManagement {...props} />}
        />
        <Route
          path="/addSkillandDrill"
          exact
          render={(props: any) => <AddSkillandDrill {...props} />}
        />

        <Route
          path="/managerDashboard"
          exact
          render={(props: any) => <ManagerDashboard {...props} />}
        />

        <PrivateRoute
          path="/admin/settings"
          exact
          render={(props: any) => <Settings {...props} />}
        />

        <PrivateRoute
          path="/admin/dashboard"
          exact
          render={props => <AdminDashboard {...rootProps} {...props} />}
        />

        <Route
          path="/emailVerify"
          exact
          render={(props: any) => <CheckEmailManager {...props} />}
        />
        <PrivateRoute
          path="/helpus"
          exact
          render={(props: any) => <HelpUS {...props} />}
        />
        <PrivateRoute
          path="/confirmation"
          exact
          render={(props: any) => <Confirmation {...props} />}
        />
        <PrivateRoute
          path="/deactivation"
          exact
          render={(props: any) => <Deactivation {...props} />}
        />
        <PrivateRoute
          path="/gymlist"
          exact
          render={(props: any) => <GymList {...props} />}
        />
        <Route
          path="/checkmail"
          exact
          render={(props: any) => <Checkmail {...props} />}
        />

        <PrivateRoute
          path="/passwordchange"
          exact
          render={(props: any) => <Passwordchange {...props} />}
        />
        <PrivateRoute
          path="/skillandDrillDetails"
          exact
          render={(props: any) => <DetailSkillandDrill {...props} />}
        />
        <PrivateRoute
          path="/admin/EquipmentList"
          exact
          render={(props: any) => <EquipmentList {...props} />}
        />
        <PrivateRoute
          path="/EquipmentAdd"
          exact
          render={(props: any) => <EquipmentAdd {...props} />}
        />
        <PrivateRoute
          path="/admin/EquipmentEdit/:EquipmentID"
          exact
          render={(props: any) => <EquipmentEdit {...props} />}
        />
        <PrivateRoute
          path="/MediaAdd"
          exact
          render={(props: any) => <MediaAdd {...props} />}
        />
        <PrivateRoute
          path="/admin/MediaEdit/:MediaID"
          exact
          render={(props: any) => <MediaEdit {...props} />}
        />
        <PrivateRoute
          path="/PlanList"
          exact
          render={(props: any) => <PlanList {...props} />}
        />
        <PrivateRoute
          path="/PlanAdd"
          exact
          render={(props: any) => <PlanAdd {...props} />}
        />
        <PrivateRoute
          path="/admin/users"
          exact
          render={(props: any) => <Users {...props} />}
        />
        <PrivateRoute
          path="/admin/UsersList"
          exact
          render={(props: any) => <UsersCatalogue {...props} />}
        />



      </Switch>
    </Suspense>
  );
}

export default withRouter(WebRoutes);
